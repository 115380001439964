<template>
  <div class="outer-wrapper">
    <div class="title-wrapper">
      <span>标准管理</span>
    </div>
    <div class="inner-wrapper">
      <div class="editor-wrapper">
        <Tabs>
          <TabPane label="标准列表" icon="ios-contacts">
            <normal-list
              :columnsFormatData="columnsFormatData"
              :askData="askData"
              :refreshData="update_count"
              main_width="1260px"
            ></normal-list>
          </TabPane>
          <Button @click="openModal('addStandard')" slot="extra" style="margin-right:5px;">添加标准</Button>
        </Tabs>
      </div>
      <Modal v-model="modalSwicth.addStandard" :width="800" :mask-closable="false" @on-cancel="closeModal('addStandard')">
      <p slot="header" class="sc-header">
        <Icon type="ios-list-box"/>
        <span>新增标准</span>
      </p>
      <i class="ivu-icon ivu-icon-ios-close" slot="close" @click="closeModal('addStandard')"></i>
      <div class="sc-content-wrapper">
        <div class="form-wrapper">
              <Form ref="formStandard" :model="formItem" :rules="ruleValidate" :label-width="250">
                <FormItem label="标准编号：" prop="sNo" style="width:438px">
                <Input v-model="formItem.sNo" />
              </FormItem>
              <FormItem label="标准名称：" prop="standardName" style="width:650px">
                <Input v-model="formItem.standardName" placeholder />
              </FormItem>
              <FormItem label="标准发布时间：" prop="pubdate" style="width:500px">
                <input type="date" v-model="formItem.pubdate" placeholder style="text-align:center;"/>
              </FormItem>
              <FormItem label="标准实施时间：" prop="exedate" style="width:500px">
                <input type="date" v-model="formItem.exedate" placeholder style="text-align:center;"/>
              </FormItem>
              <FormItem label="上传标准文件（要求pdf格式）：" prop="pdfFile">
                <div>
                  <Upload
                    ref="upload"
                    :before-upload="handleUpload"
                    :on-success="handleSuccess"
                    action=""
                  >
                    <Button class="button-wrapper" icon="ios-cloud-upload-outline">请选择上传文件</Button>
                  </Upload>
                  <div v-if="file !== null" class="file-space-wrapper">
                    <div class="file-space">
                      <div class="file-icon-wrapper">
                        <Icon type="ios-document-outline" :size="50"/>
                      </div>
                      <div class="fp-inner">
                        <p>{{ file.name }}</p>
                        <p>{{file.size/1000+"K"}}</p>
                      </div>
                      <div class="del-icon-wrapper">
                        <Button
                          class="del-button"
                          @click="handleRemove"
                          type="text"
                          icon="ios-trash"
                          size="large"
                        ></Button>
                      </div>
                    </div>
                    <div class="button-wrapper"></div>
                  </div>
                </div>
              </FormItem>
            </Form>
            </div>
        </div>
        <div slot="footer">
        <Button @click="handleSubmit('formStandard')" :loading="loadingStatus" size="large">{{loadingStatus ? 'Uploading' : '确认提交' }}</Button>
        <Button @click="handleReset" size="large" style="margin-left: 8px">重置</Button>
        <Button size="large" @click="closeModal('addStandard')" class="cancel-button">取消</Button>
      </div>
    </Modal>
      <Modal v-model="modalSwicth.alterStandard" :width="800" :mask-closable="false" @on-cancel="closeModal('alterStandard')">
      <p slot="header" class="sc-header">
        <Icon type="ios-list-box"/>
        <span>标准修改</span>
      </p>
      <i class="ivu-icon ivu-icon-ios-close" slot="close" @click="closeModal('alterStandard')"></i>
      <div class="sc-content-wrapper">
        <Form ref="formStandardAlter" :model="formItemAlter" :rules="ruleValidateAlter" :label-width="250">
              <FormItem label="标准编号：" prop="sNo" style="width:438px">
                <Input v-model="formItemAlter.sNo"  />
              </FormItem>
              <FormItem label="标准名称：" prop="standardName" style="width:650px">
                <Input v-model="formItemAlter.standardName" placeholder />
              </FormItem>
              <FormItem label="标准发布时间：" prop="pubdate" style="width:500px">
                <input type="date" v-model="formItemAlter.pubdate" placeholder style="text-align:center;"/>
              </FormItem>
              <FormItem label="标准实施时间：" prop="exedate" style="width:500px">
                <input type="date" v-model="formItemAlter.exedate" placeholder style="text-align:center;"/>
              </FormItem>
              <FormItem label="上传标准文件（要求pdf格式）：" prop="pdfFile">
                <div>
                  <Upload
                    ref="upload"
                    :before-upload="handleUploadAlter"
                    :on-success="handleSuccess"
                    action=""
                  >
                    <Button class="button-wrapper" icon="ios-cloud-upload-outline">请选择上传文件</Button>
                  </Upload>
                  <div v-if="fileAlter !== null" class="file-space-wrapper">
                    <div class="file-space">
                      <div class="file-icon-wrapper">
                        <Icon type="ios-document-outline" :size="50"/>
                      </div>
                      <div class="fp-inner">
                        <p>{{ fileAlter.name }}</p>
                        <p>{{fileAlter.size/1000+"K"}}</p>
                      </div>
                      <div class="del-icon-wrapper">
                        <Button
                          class="del-button"
                          @click="handleRemove"
                          type="text"
                          icon="ios-trash"
                          size="large"
                        ></Button>
                      </div>
                    </div>
                    <div class="button-wrapper"></div>
                  </div>
                </div>
              </FormItem>
              </Form>
      </div>
      <div slot="footer">
        <Button size="large" @click="modal_save"  class="confirm-button">确定</Button>
        <Button size="large" @click="closeModal('alterStandard')" class="cancel-button">取消</Button>
      </div>
    </Modal>
    <Drawer
      title="查看文件"
      v-model="modalSwicth.fileView"
      width="900"
      :mask-closable="false"
      placement="left"
      @on-close="closeModal('fileView')"
    >
      <iframe height="99%" width="100%" :src="pdfUrl + checkUrl"> </iframe>
    </Drawer>
    </div>
  </div>
</template>
<script>
import normalList from "@/components/Common/NormalList.vue";

let userToken = localStorage.getItem("current_user_token");
let authData = JSON.parse(localStorage.getItem("authData"));

function formItemFac(){
  return {
    id:0,
    pubdate: null,
    exedate: null,
    standardName: "",
    pdfFile: "",
    sNo:""
  };
}

export default {
  name: "standardManage",
  components: {
    normalList,
  },
  props: {},
  data() {
    return {
      askData: "standardList",
      pdfUrl: "/pdf/web/viewer.html?file=",
      checkUrl: "",
      update_count: 1,
      columnsFormatData: [
        {
          title: '标准号',
          width:200,
          align: "center",
          key:'standard_no'
        },
        {
          title: '标准名称',
          key: 'name',
          align: "center",
        }, 
        {
          title: '发布日期',
          width:150,
          align: "center",
          key:'pubdate'
        }, 
        {
          title: '实施日期',
          width:150,
          align: "center",
          key:'exedate'
        },    
        {
          title: "操作",
          align: "center",
          width: 300,
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    size: "small",
                    disabled: !params.row.file_url,
                  },
                  style: { marginRight: "10px", color: "#993322" },
                  on: {
                    click: () => {
                      this.checkUrl = encodeURIComponent(
                        this.$fileUrlFac(params.row.file_url,authData.sfileRight)
                      );
                      this.modalSwicth.fileView = true;
                    },
                  },
                },
                "查看"
              ),
              h(
                "Button",
                {
                  props: {
                    size: "small"
                  },
                  style: { marginRight: "10px", color: "green" },
                  on: {
                    click: () => {
                      this.modalSwicth.alterStandard = true;
                      this.formItemAlter.id = params.row.id;
                      this.formItemAlter.sNo = params.row.standard_no;
                      this.formItemAlter.standardName = params.row.name;
                      this.formItemAlter.pubdate = params.row.pubdate;
                      this.formItemAlter.exedate = params.row.exedate;
                    }
                  }
                },
                "修改"
              ),
              h(
                "Button",
                {
                  props: {
                    size: "small"
                  },
                  style: { marginRight: "10px",color: "red" },
                  on: {
                    click: () => {
                      this.confirmBox(params.row);
                    }
                  }
                },
                "删除"
              ),
              h(
                "Button",
                {
                  props: {
                    size: "small"
                  },
                  style: { color: "blue" },
                  on: {
                    click: () => {
                      window.open('/manage/clauseManage/'+params.row.enid,'_blank');
                    }
                  }
                },
                "管理条目"
              ),
            ]);
          }
        }
      ],
      modalSwicth:{
        addStandard:false,
        alterStandard:false,
        fileView:false,
      },
      formItem: formItemFac(),
      formItemAlter:formItemFac(),
      ruleValidate: {
        sNo: [
          {
            required: true,
            type: "string",
            message: "请输入标准编号！",
            trigger: "blur"
          }
        ],
        standardName: [
          {
            required: true,
            type: "string",
            message: "请输入标准名称！",
            trigger: "blur"
          }
        ],
        pdfFile: [
          {
            type: "string",
            required: true
          }
        ]
      },
      ruleValidateAlter: {
        sNo: [
          {
            required: true,
            type: "string",
            message: "请输入标准编号！",
            trigger: "blur"
          }
        ],
        standardName: [
          {
            required: true,
            type: "string",
            message: "请输入标准名称！",
            trigger: "blur"
          }
        ],
      },
      loadingStatus: false,
      atimated: false,
      file: null,
      fileAlter:null,
    };
  },
  watch: {},
  computed: {
    
  },
  created() {
  },
  methods: {
    openModal(value){
      this.modalSwicth[value] = true;
    },
    closeModal(value){
      this.modalSwicth[value] = false;
    },
    // getStaticOption(){
    //   this.$axios
    //       .post(this.$url + "/ask_static_options", {
    //         userToken: userToken,
    //         askData:'standardManage'
    //       })
    //       .then(response => {
    //         if (response.data.stat == "ok") {
    //           this.standardOptions = response.data.res_record;
    //         } else {
    //           this.$Massage.error("获取选项数据失败，网络存在问题！");
    //         }
    //       });
    // },
    confirmBox(value) {
      this.$Modal.confirm({
        title: "确认删除：",
        content: "<p>" + "标准：" + value.standard_no+' '+'《'+value.name+'》' + "</p>",
        onOk: () => {
          this.recordFormatDel(value.id);
        },
        onCancel: () => {}
      });
    },
    recordFormatDel(id){
      let form = new FormData();
      form.append("userToken", userToken);
      form.append("askData", 'delStandard');
      form.append("id", id);
      let config = {
            headers: { "Content-Type": "multipart/form-data" }
          };
      this.$axios
        .post(this.$url + "/archive_manage", form, config)
        .then(response => {
          if(response.data.stat==='ok'){
          this.$Message.success("删除成功！");
          this.update_count ++;
          }
        })
        .catch(error => {
          this.$Message.warning(error+"网络错误！操作失败。");
        });
    },
    handleReset() {
      for (let item in this.formItem) {
        this.formItem[item] = "";
      }
      this.file = null;
    },
    handleRemove() {
      this.file = null;
    },
    handleSuccess() {},
    handleUpload(file) {
      if (file.name.split(".").pop() !== "pdf") {
        this.$Notice.warning({
          title: "文件格式不符",
          desc:
            "文件： " + file.name + " 的格式不正确，请选择后缀为“.pdf”的文件!"
        });
      } else if (file.size > 10240000) {
        this.$Notice.warning({
          title: "文件太大",
          desc: "文件：  " + file.name + " 的大小超出限制, 不能大于10MB!"
        });
      } else {
        this.file = file;
        this.formItem.pdfFile = file.name;
      }
      return false;
    },
    handleUploadAlter(file) {
      if (file.name.split(".").pop() !== "pdf") {
        this.$Notice.warning({
          title: "文件格式不符",
          desc:
            "文件： " + file.name + " 的格式不正确，请选择后缀为“.pdf”的文件!"
        });
      } else if (file.size > 10240000) {
        this.$Notice.warning({
          title: "文件太大",
          desc: "文件：  " + file.name + " 的大小超出限制, 不能大于10MB!"
        });
      } else {
        this.fileAlter = file;
        this.formItemAlter.pdfFile = file.name;
      }
      return false;
    },
    modal_save(){
      this.$refs["formStandardAlter"].validate(valid => {
        if (valid) {
          let fileObj = this.fileAlter;
          let form = new FormData();
          form.append("file", fileObj);
          form.append("userToken", userToken);
          form.append("askData", 'alterStandard');
          form.append("info", JSON.stringify(this.formItemAlter));
          this.loadingStatus = true;
          let config = {
            headers: { "Content-Type": "multipart/form-data" }
          };
          this.$axios
            .post(this.$url + "/archive_manage", form, config)
            .then(response => {
              this.handleReset();
              this.loadingStatus = false;
              if (response.data.stat === "ok") {
                this.$Message.success("标准信息修改成功！");
                this.modalSwicth.alterStandard = false;
                this.formItemAlter = formItemFac();
                this.update_count++;
              } else {
                this.$Message.error(response.data);
              }
            })
            .catch(error => {
              this.fileAlter = null;
              this.loadingStatus = false;
              this.$Message.error(error + "未知错误！");
            });
        } else {
          this.$Message.error("填入信息错误!");
        }
      });

    },
    handleSubmit(name) {
      this.$refs[name].validate(valid => {
        if (valid) {
          let fileObj = this.file;
          let form = new FormData();
          form.append("file", fileObj);
          form.append("userToken", userToken);
          form.append("askData", 'uploadStandard');
          form.append("info", JSON.stringify(this.formItem));
          this.loadingStatus = true;
          let config = {
            headers: { "Content-Type": "multipart/form-data" }
          };
          this.$axios
            .post(this.$url + "/archive_manage", form, config)
            .then(response => {
              this.handleReset();
              this.loadingStatus = false;
              if (response.data.stat === "ok") {
                this.$Message.success("标准添加成功！");
                this.update_count++;
                this.modalSwicth.addStandard = false;
              } else {
                this.$Message.error(response.data);
              }
            })
            .catch(error => {
              this.file = null;
              this.loadingStatus = false;
              console.log(error);
              this.$Message.error("操作失败！请确认输入内容是否无误！");
            });
        } else {
          this.$Message.error("填入信息错误!");
        }
      });
    }
  }
};
</script>
<style scoped>
.inner-wrapper {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
}

.title-wrapper {
  text-align: center;
  font-size: 20px;
  padding: 16px;
}

.editor-wrapper,
.preview-wrapper {
  margin: 0px 20px 40px;
  width: 1280px;
}

.editor-title,
.preview-title {
  font-size: 14px;
  text-align: center;
  padding: 10px 0;
}

.re-l {
  min-height: 750px;
  color: #000;
  border: #aaa solid 1px;
  box-shadow: 1px 2px 1px #aaa;
  text-align: center;
  position: relative;
  margin: 5px;
  padding: 0 10px;
}

.form-wrapper {
  text-align: left;
  padding: 80px 120px 80px 50px;
}
</style>